"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.onError = void 0;
var _conf = _interopRequireDefault(require("@tvg/conf"));
var _requester = _interopRequireDefault(require("@tvg/api/requester"));
var _helper = require("../services/helper");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const onError = () => ({
  error: {
    status: "error",
    message: "Id request fail"
  },
  success: null
});
exports.onError = onError;
const onSuccess = response => ({
  error: null,
  success: {
    status: "success",
    wagerProfile: response.data.wagerProfile,
    accountNumber: response.data.accountNumber
  }
});
var _default = () => (0, _requester.default)()({
  method: "get",
  url: `${(0, _conf.default)().config("service.usa")}/session/context`,
  headers: (0, _helper.tvgHeaders)(),
  withCredentials: true
}).then(onSuccess).catch(onError);
exports.default = _default;