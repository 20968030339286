// @flow
import React, { type Node } from "react";
import type { NullaryFn } from "@tvg/types/Functional";

import Icon from "../../_static/Icons";
import { close } from "../../_static/Icons/icons";
import messageBoxStylesDefault, { styleMapBySubtype } from "./styles";
import type { MessageBoxType, MessageBoxSubtype } from "./types";

import {
  HorizontalMessageBox,
  IconTextWrapper,
  MessageIcon,
  TextWrapper,
  MessageTitle,
  MessageContent,
  CloseBtn,
  ActionLink
} from "./styled-components";

type Props = {
  /**
   * Type of the placeholder
   */
  type?: MessageBoxType,
  /**
   * Type of the placeholder
   */
  subtype?: MessageBoxSubtype,
  /**
   * Title
   */
  title?: string,
  /**
   * Message to be displayed
   */
  message: Node,
  /**
   * Children to be rendered inside the component
   */
  children?: Node,
  /**
   * Close callback
   */
  onClose?: NullaryFn<mixed>,
  /**
   * Boolean to choose if message has icon or not
   */
  hasIcon?: boolean,
  /**
   * An optional url so that the message box turns into a link
   */
  callToAction?: {
    /**
     * Text link to be shown
     */
    textLink: string,
    /**
     * An optional url
     */
    url: string,
    /**
     * An optional callback to execute
     */
    onClick: NullaryFn<mixed>
  },
  /**
   * Classname to extend the component style
   */
  className?: string,
  /**
   * qa Label
   */
  qaLabel?: string
};

const MessageBoxV2 = (props: Props) => {
  const renderMessage = (color: ?string, inlineText: ?boolean) => {
    const { message: PropsMessage, qaLabel = "" } = props;
    const Message = React.isValidElement(PropsMessage) ? (
      <MessageContent
        data-qa-label={`${qaLabel}message-box`}
        color={color}
        inlineText={inlineText}
      >
        {PropsMessage}
      </MessageContent>
    ) : (
      // eslint-disable-next-line react/no-danger
      <MessageContent
        color={color}
        inlineText={inlineText}
        dangerouslySetInnerHTML={{ __html: PropsMessage }}
      />
    );
    return Message;
  };

  const getMsgSubtypeProps = () =>
    (props.subtype && styleMapBySubtype[props.subtype]) || {};

  const { type, hasIcon, title, children, callToAction, className, onClose } =
    props;

  // $FlowFixMe
  const style = messageBoxStylesDefault[type];
  const messageProps = getMsgSubtypeProps();
  const hasTitle = !!title;
  return (
    <HorizontalMessageBox
      customStyle={style}
      hasIcon={hasIcon}
      hasTitle={hasTitle}
      type={type}
      className={className}
      // $FlowFixMe
      data-qa-label={`${props.qaLabel || ""}-${type}`}
      {...messageProps}
    >
      <IconTextWrapper>
        {hasIcon && (
          <MessageIcon
            icon={style.icon}
            strokeColorList={style.strokeColorList || []}
            size={16}
            color={style.fill}
            qaLabel={`${props.qaLabel || ""}-icon`}
            viewBoxSize={style.viewBoxSize || 1024}
          />
        )}
        <TextWrapper
          hasIcon={hasIcon}
          data-qa-label={`${props.qaLabel || ""}-text`}
          inlineText={messageProps.inlineText}
        >
          {title && (
            <MessageTitle
              data-qa-label={`${props.qaLabel || ""}-title`}
              color={style.color}
              inlineText={messageProps.inlineText}
            >
              {messageProps.inlineText ? `${title} - ` : title}
            </MessageTitle>
          )}
          {renderMessage(style.color, messageProps.inlineText)}
          {messageProps.hasCallToAction && callToAction && (
            <ActionLink
              to={callToAction.url}
              onClick={callToAction.onClick}
              color={style.color}
            >
              {callToAction.textLink}
            </ActionLink>
          )}
        </TextWrapper>
        {messageProps.hasCloseButton && onClose && (
          <CloseBtn
            onClick={onClose}
            data-qa-label={`${props.qaLabel || ""}-closeBtn`}
          >
            <Icon icon={close} size={16} color={style.color} />
          </CloseBtn>
        )}
      </IconTextWrapper>
      {children}
    </HorizontalMessageBox>
  );
};

// $FlowFixMe
MessageBoxV2.defaultProps = {
  type: "default",
  subtype: "floating",
  hasIcon: true,
  children: null,
  className: "",
  qaLabel: "messageBox"
};

export default MessageBoxV2;
