type FeaturesRequestSuccessAction = {
  type: "FEATURES_GET_SUCCESS";
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    featureToggles: any;
  };
};

type FeaturesRequestFailAction = {
  type: "FEATURES_GET_FAIL";
  payload: Error;
  error: true;
};

type FeaturesChange = {
  type: "FEATURES_CHANGE";
  payload: {
    toggleName: string;
    newValue: boolean;
  };
};

type FeaturesResponseAction = {
  type: "FEATURES_RESPONSE";
  payload: {
    features: [];
  };
};

type MessagesRequestSuccessAction = {
  type: "MESSAGES_GET_SUCCESS";
  payload: {
    messages: false;
  };
};

type MessagesRequestFailAction = {
  type: "MESSAGES_GET_FAIL";
  payload: Error;
  error: true;
};

export type CapiActions =
  | MessagesRequestFailAction
  | MessagesRequestSuccessAction
  | FeaturesRequestFailAction
  | FeaturesRequestSuccessAction
  | FeaturesResponseAction
  | FeaturesChange;

export const successFeaturesRequest = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  featureToggles: any
): FeaturesRequestSuccessAction => ({
  type: "FEATURES_GET_SUCCESS",
  payload: {
    featureToggles
  }
});

export const failFeaturesRequest = (err: Error): FeaturesRequestFailAction => ({
  type: "FEATURES_GET_FAIL",
  payload: err,
  error: true
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const featuresResponse = (features: any): FeaturesResponseAction => ({
  type: "FEATURES_RESPONSE",
  payload: {
    features
  }
});

export const successMessagesRequest = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messagesData: any
): MessagesRequestSuccessAction => ({
  type: "MESSAGES_GET_SUCCESS",
  payload: {
    messages: messagesData
  }
});

export const failMessagesRequest = (err: Error): MessagesRequestFailAction => ({
  type: "MESSAGES_GET_FAIL",
  payload: err,
  error: true
});
