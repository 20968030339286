import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import mediator from "@tvg/mediator";
import Logo from "@tvg/atomic-ui/_static/Logos";
import { noop } from "lodash";
import HeaderContainer from "./styled-components";

type Props = {
  device: string;
  brand: string;
  isRn: boolean;
};

const Header = (props: Props) => {
  const isMobile = props.device === "mobile";
  const handleNavigation = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (props.device === "desktop") {
        mediator.base.dispatch({
          type: "TVG4_NAVIGATION",
          payload: { route: "/home" }
        });
      } else {
        mediator.base.dispatch({ type: "LEAVE_CRF" });
      }
      e.stopPropagation();
      e.preventDefault();
    },
    []
  );

  useEffect(() => {
    mediator.base.subscribe("LEAVE_CRF", noop);
  }, []);

  return (
    <HeaderContainer isMobile={isMobile} isRn={props.isRn}>
      <Link to="/home" onClick={handleNavigation}>
        <Logo
          height={isMobile ? 18 : 28}
          brand={props.brand}
          data-qa-label="tvgLogo"
          isDark
        />
      </Link>
    </HeaderContainer>
  );
};

export default Header;
