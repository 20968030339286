"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _lodash = require("lodash");
var _conf = _interopRequireDefault(require("@tvg/conf"));
var _errorMessages = _interopRequireDefault(require("./errorMessages.json"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const validateGeoPacket = (userId, geo) => {
  const tvg = (0, _conf.default)();
  return (0, _axios.default)({
    method: "POST",
    url: `${tvg.config().service.geo}/geopacket`,
    headers: {
      Accept: "application/json",
      "x-tvg-context": tvg.context()
    },
    data: {
      geo,
      userId
    }
  });
};
class GeoComplySvc {
  constructor() {
    this.geoClient = null;
    this.geoLicenseExpiration = null;
    this.geoLicense = null;
    this.hasChecked = false;
    this.getPack = this.getPack.bind(this);
    this.getLicence = this.getLicence.bind(this);
    this.getGeoPacket = this.getGeoPacket.bind(this);
    this.clientEmitMessage = this.clientEmitMessage.bind(this);
    this.validateGeoPacket = validateGeoPacket;
    this.softErrorMessage = "geocomply browser error";
    if (typeof window !== "undefined") {
      this.geoComplyIsPresent = window.GcHtml5;
      if (!this.geoComplyIsPresent) {
        console.log(_errorMessages.default.missingGeo);
      } else {
        this.geoClient = window.GcHtml5.createClient(null, null);
        this.geoClient.setReason("GeoComply login for TVG");
        this.geoClient.events.on("engine.success", (text, xml) => {
          this.clientEmitMessage({
            success: {
              text,
              xml
            }
          });
        });
        this.geoClient.events.on("browser.failed", code => {
          this.clientEmitMessage({
            error: {
              code,
              message: this.softErrorMessage
            }
          });
        });
        this.geoClient.events.on("revised.failed", code => {
          this.clientEmitMessage({
            error: {
              code,
              message: this.softErrorMessage
            }
          });
        });
        this.geoClient.events.on("config.failed", (code, message) => {
          this.clientEmitMessage({
            error: {
              code,
              message
            }
          });
        });
        this.geoClient.events.on("engine.failed", (code, message) => {
          this.clientEmitMessage({
            error: {
              code,
              message
            }
          });
        });
      }
    }
  }
  clientEmitMessage(answer) {
    this.geoClient.events.emit("geoPacketAvailable", answer);
  }
  getGeoPacket(userId, type = "login") {
    if (!this.geoComplyIsPresent || (0, _conf.default)().brand !== "4njbets") {
      return Promise.reject({});
    }
    if (!this.geoClient) {
      this.geoClient = window.GcHtml5.createClient(null, null);
    }
    if (type === "revalidate") {
      this.geoClient.setReason("GeoComply revalidate for TVG");
    } else {
      this.geoClient.setReason("GeoComply login for TVG");
    }
    if (!this.geoLicense) {
      return this.getLicence(userId).then(() => this.getPack(userId));
    }
    return this.getPack(userId).then(res => {
      this.hasChecked = false;
      return res;
    }).catch(err => {
      this.hasChecked = false;
      return err;
    });
  }
  getLicence(userId) {
    const tvg = (0, _conf.default)();
    return (0, _axios.default)({
      method: "get",
      url: `${tvg.config().service.geo}/license${this.geoLicenseExpiration ? `?currentLicenseTimestamp=${this.geoLicenseExpiration}` : ""}`,
      headers: {
        Accept: "application/json",
        "x-tvg-context": tvg.context()
      }
    }).then(response => {
      this.geoLicenseExpiration = response.data.expiration;
      this.geoLicense = response.data.license;
      return userId;
    }).catch(() => {
      this.geoLicenseExpiration = null;
      this.geoLicense = null;
      return undefined;
    });
  }
  getPack(userId) {
    if (!this.geoClient || !this.geoLicense) {
      return Promise.reject(_errorMessages.default.missingGeo);
    }
    if (!userId) {
      return Promise.reject(_errorMessages.default.missingUserID);
    }
    return new Promise((resolve, reject) => {
      this.geoClient.events.on("geoPacketAvailable", data => {
        if (data.success) {
          return resolve({
            geo: data.success.text
          });
        }
        return reject(data.error);
      });
      this.geoClient.setUserId(userId.trim().toLowerCase());
      this.geoClient.setLicense(this.geoLicense);
      this.geoClient.request();
    });
  }
}
exports.default = GeoComplySvc;
module.exports = exports.default;