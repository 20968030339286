/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import React, { useEffect } from "react";
import { get, noop } from "lodash";
import mediator from "@tvg/mediator";
import queryString from "query-string";
import ResetCredentials from "@tvg/reset-credentials";
import WebviewHeader from "../webview-header";
import perf from "../../utils/perf";
import Wrapper from "./styled-components";

type Props = {
  isLogged: boolean;
  device: string;
  product: string;
  brand: string;
  location: Location;
};

export const CredentialsRecovery = (props: Props) => {
  useEffect(() => {
    perf.measure("page_render", { page: "reset-credentials" });
  }, [props]);

  const urlParams = queryString.parse(get(props.location, "search"));

  useEffect(() => {
    mediator.base.subscribe("LEAVE_CRF", noop);
    mediator.base.subscribe("OPEN_EXTERNAL_URL", noop);
    mediator.base.subscribe("LEAVE_CRF_SUCCESS", noop);
  }, []);

  return (
    <Wrapper device={props.device} isRn={props.product === "iosnative"}>
      <WebviewHeader
        device={props.device}
        brand={props.brand}
        isRn={props.product === "iosnative"}
      />
      <ResetCredentials
        isLogged={props.isLogged}
        token={urlParams.token}
        resend={urlParams.resend}
        device={props.device}
        product={props.product}
      />
    </Wrapper>
  );
};

export default CredentialsRecovery;
