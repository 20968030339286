"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lodash = require("lodash");
var _cookies = require("@tvg/utils/cookies");
var _login = require("../requests/login");
var _logout = _interopRequireDefault(require("../requests/logout"));
var _idRequest = _interopRequireDefault(require("../requests/idRequest"));
var _profile = _interopRequireDefault(require("../requests/profile"));
var _helper = require("../services/helper");
var _static = require("../services/static");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
class LoginService {
  static doLogin(payload) {
    _helper.TimeDiffService.markStart(_static.CONSTANTS.METRIC_USER_LOGIN);
    return (0, _login.loginAction)(payload).then(response => {
      if (response.success) {
        response.success.data.userDetails.pin = payload.pin;
        (0, _cookies.cookieService)("wp", true, (0, _lodash.get)(response, "success.data.userDetails.profile"));
        (0, _cookies.cookieService)("hasLoginOnce", true, "true", 31622400);
        (0, _lodash.attempt)(() => {
          sessionStorage.setItem("userId", (0, _lodash.get)(response, "success.data.userDetails.accountNumber"));
          localStorage.setItem("userAuthenticationState", (0, _lodash.get)(payload, "stateAbbr"));
        });
      }
      if (payload && payload.callback && typeof payload.callback === "function") {
        payload.callback(response.error, response.success);
      }
      return response;
    }).catch(() => {});
  }
  static doLogout(payload) {
    return (0, _logout.default)().then(response => {
      if (payload && payload.callback && typeof payload.callback === "function") {
        payload.callback(response.error, response.success);
      }
      if (response && response.success && typeof window !== "undefined") {
        (0, _cookies.cookieService)("wp", false);
        (0, _lodash.attempt)(() => {
          sessionStorage.removeItem("userId");
          localStorage.removeItem("isFirstTouch");
          localStorage.removeItem("userAuthenticationState");
        });
      }
      return response;
    }).catch(() => {});
  }
  static requestProfile(userId) {
    return (0, _profile.default)(userId).then(response => {
      if (response.success) {
        (0, _cookies.cookieService)("wp", true, (0, _lodash.get)(response, "success.data.user.profile"));
        (0, _cookies.cookieService)("hasLoginOnce", true, "true", 31622400);
      }
      return response;
    }).catch(() => {});
  }
  static validateSession() {
    return (0, _idRequest.default)().then(response => {
      if (response.success) {
        (0, _lodash.attempt)(() => {
          sessionStorage.setItem("userId", (0, _lodash.get)(response, "success.accountNumber"));
          (0, _cookies.cookieService)("wp", true, (0, _lodash.get)(response, "success.wagerProfile"));
          (0, _cookies.cookieService)("hasLoginOnce", true, "true", 31622400);
        });
        return this.requestProfile((0, _lodash.get)(response, "success.accountNumber"));
      }
      return response;
    }).catch(() => {});
  }
}
exports.default = LoginService;
module.exports = exports.default;