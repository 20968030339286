// @flow

import React, { PureComponent, type Node } from "react";
import { noop } from "lodash";
import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";
import type { TagType, ButtonSize, ButtonType } from "./Types";
import { StyledLink, StyledRef } from "./styled-components";
import { getSize, getType } from "./styles";

type toObject = {
  pathname?: string,
  search?: string,
  hash?: string,
  state?: any
};

type Props = {
  /**
   * className for custom styling
   */
  className: string,
  /**
   * children of the Text component
   */
  children: Node,
  /**
   * Target url
   */
  url: string | toObject,
  /**
   * Button size 'default' | 'small' | 'big'
   */
  size: ButtonSize,
  /**
   * Button type 'primary' | 'secondary' | 'secondary_alt' | 'tertiary' | 'tertiary_alt' | 'bet' | 'marketing' | 'delete'
   */
  type: ButtonType,
  /**
   * Disables the button
   */
  isDisabled: boolean,
  /**
   * Button with border radius
   */
  hasRoundedCorners: boolean,
  /**
   * Button with full width
   */
  isStretched: boolean,
  /**
   * Tag type 'link' | 'a'
   */
  tag: TagType,
  /**
   * qaLabel for test
   */
  qaLabel: string,
  /**
   * On click callback
   */
  onClick: NullaryFn<mixed> | UnaryFn<Event, mixed>,
  /**
   * Button with shadow
   */
  hasShadow: boolean
};

export default class ButtonLink extends PureComponent<Props> {
  static defaultProps = {
    className: "",
    children: null,
    url: "",
    size: "default",
    type: "primary",
    tag: "link",
    isDisabled: false,
    hasRoundedCorners: true,
    isStretched: false,
    qaLabel: "",
    onClick: noop,
    hasShadow: false
  };

  render() {
    const sizeSpecs = getSize(this.props.size);
    const typeSpecs = getType(this.props.type);
    const componentProps = {
      ...(this.props.qaLabel !== ""
        ? {
            "data-qa-label": this.props.qaLabel
          }
        : {}),
      ...{
        size: this.props.size,
        type: this.props.type,
        className: this.props.className
      },
      ...(this.props.tag === "link"
        ? {
            to: this.props.url,
            state:
              typeof this.props.url !== "string"
                ? this.props.url.state
                : undefined
          }
        : { href: this.props.url, as: this.props.tag }),
      onClick: this.props.onClick,
      $sizeSpecs: sizeSpecs,
      $typeSpecs: typeSpecs,
      tag: this.props.tag,
      $isStretched: this.props.isStretched,
      $isDisabled: this.props.isDisabled,
      $hasRoundedCorners: this.props.hasRoundedCorners,
      $hasShadow: this.props.hasShadow
    };

    return this.props.tag === "link" ? (
      <StyledLink {...componentProps}>{this.props.children}</StyledLink>
    ) : (
      <StyledRef {...componentProps}>{this.props.children}</StyledRef>
    );
  }
}
