// @flow
// $FlowFixMe
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get, isEqual } from "lodash";

import { isTvg5 } from "@tvg/utils/generalUtils";
import mediator from "@tvg/mediator";
import { openLiveChat } from "@tvg/utils/liveChatUtils";
import { useNavigate } from "@tvg/custom-hooks";
import {
  postUserCredentialsReset,
  postNewCredential,
  postNewPin,
  postResendCredentialsRecovery
} from "@tvg/api/crf";
import parseCapiMessage, { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import CredentialsReset, {
  type ResetCredentialsType
} from "@tvg/atomic-ui/_organism/CredentialsReset";

export type ResetType = "password" | "pin";

type MessageType = {
  error: {
    title: string,
    message: string,
    buttonText: string,
    linkText: string
  },
  success: {
    title: string,
    message: string
  },
  expiredToken: {
    title: string,
    message: string,
    buttonText: string,
    linkText: string
  },
  modal: {
    title: string,
    message: string,
    buttonText?: string,
    linkText?: string,
    returnToHomepage?: string
  },
  isInvalidPassword: {
    title: string,
    message: string,
    buttonText: string,
    linkText: string
  }
};

type Props = {
  isLogged: boolean,
  resetType: ResetType,
  resetCredentialsConfig: Array<ResetCredentialsType>,
  resetCredentialsMsgs: MessageType,
  resentEmailMessage: {
    title: string,
    message: string
  },
  resentEmailPageMessage: {
    title: string,
    message: string
  },
  returnText: string,
  token: string,
  resend: string,
  device: string,
  product: string
};

const getModalData = (
  resetCredentialsMsgs: MessageType,
  config: ResetCredentialsType
) => {
  const { inputPlaceholder } = config;
  const { modal } = resetCredentialsMsgs;

  if (!modal || !inputPlaceholder) return modal;

  const message = replaceCAPIVariables(modal.message, {
    inputPlaceholder: inputPlaceholder.toLowerCase()
  });
  const buttonText = replaceCAPIVariables(modal.buttonText || "", {
    inputPlaceholder
  });

  return { ...modal, message, buttonText };
};

const RecoveryTypePin = 1;
const ExpiredToken = 117410;
const InvalidPassword = 117411;

const ResetCredentials = (props: Props) => {
  const history = useNavigate();
  const [newCredential, setNewCredential] = useState("");
  const [rulesValidated, setRulesValidated] = useState([]);
  const [user, setUser] = useState({});
  const [config, setConfig] = useState<ResetCredentialsType>({});
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [hasResentEmail, setHasResentEmail] = useState(false);

  const expiredTokenMsg = get(props.resetCredentialsMsgs, "expiredToken");
  const invalidPasswordMsg = get(props.resetCredentialsMsgs, "invalidPassword");
  const defaultMsg = get(props.resetCredentialsMsgs, "error");
  const errorDataMapper = {
    [ExpiredToken]: expiredTokenMsg,
    [InvalidPassword]: invalidPasswordMsg
  };

  const gtmHandler = (payload, type) => {
    mediator.base.dispatch({
      type,
      payload: { field: payload }
    });
  };

  useEffect(() => {
    if (props.resend) {
      postResendCredentialsRecovery(props.token)
        .then(() => {
          gtmHandler("Success", "FORGOT_CREDENTIALS_EMAIL_LINK_LANDED");
          setHasResentEmail(true);
          setInitialLoading(false);
        })
        .catch(() => {
          setHasError(true);
        });
    } else {
      postUserCredentialsReset(props.token)
        .then((res: Object) => {
          gtmHandler("Success", "FORGOT_CREDENTIALS_EMAIL_LINK_LANDED");
          setUser(res.data);
        })
        .catch((err) => {
          setHasError(true);
          const code = get(err, "response.data.code");
          setErrorCode(code);
          if (code === ExpiredToken) {
            gtmHandler("Link Expired", "FORGOT_CREDENTIALS_EMAIL_LINK_LANDED");
          }
        });
    }
  }, []);

  useEffect(() => {
    if (props.isLogged) {
      mediator.base.dispatch({ type: "TVG_LOGIN:DO_LOGOUT" });
    }
  }, [props.isLogged]);

  useEffect(() => {
    if (isEqual(user, {}) || isEqual(props.resetCredentialsConfig, {})) return;

    const initialConfig = props.resetCredentialsConfig.find(
      (credentials) => credentials.recoveryType === user.recoveryType
    );

    const requirements = initialConfig && initialConfig.requirements;

    setRulesValidated(
      requirements &&
        requirements.map((rule) => ({
          ...rule,
          verified: false
        }))
    );

    setConfig(initialConfig);
    setInitialLoading(false);
  }, [JSON.stringify(user), JSON.stringify(props.resetCredentialsConfig)]);

  useEffect(() => {
    setRulesValidated(
      rulesValidated.map((rule) => ({
        ...rule,
        verified: new RegExp(rule.rule).test(newCredential)
      }))
    );
  }, [newCredential]);

  const openLogin = () => {
    mediator.base.dispatch({
      type: "OPEN_LOGIN",
      payload: {
        hasSuccessMessage: true,
        successMessage: {
          user:
            config.userIdField !== "email" ||
            (config.userIdField === "email" &&
              get(user, "username") === get(user, "email"))
              ? get(user, `${config.userIdField}`)
              : null,
          title: replaceCAPIVariables(
            get(props.resetCredentialsMsgs, "success.title"),
            {
              inputPlaceholder: config.inputPlaceholder
            }
          ),
          message: replaceCAPIVariables(
            get(props.resetCredentialsMsgs, "success.message"),
            {
              userIdLabel: config.userIdLabel.toLowerCase(),
              inputPlaceholder: config.inputPlaceholder.toLowerCase()
            }
          )
        }
      }
    });
  };

  const navigateToHome = (label): void => {
    gtmHandler(label, "FORGOT_CREDENTIALS_LEAVE_PAGE_CTA_CLICK");
    if (props.product === "iosnative") {
      mediator.base.dispatch({ type: "LEAVE_CRF" });
      return;
    }

    if (props.device === "desktop" && !isTvg5()) {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: "/" }
      });
      return;
    }

    setIsModalOpen(false);
    history.push("/");
  };

  const submitHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    gtmHandler(config.inputPlaceholder, "FORGOT_CREDENTIALS_UPDATE_CREDENTIAL");

    setLoading(true);

    const isRecoveryTypePin = user.recoveryType === RecoveryTypePin;
    const postCredentials = isRecoveryTypePin ? postNewPin : postNewCredential;

    postCredentials(props.token, newCredential)
      .then(() => {
        setLoading(false);
        gtmHandler(
          config.inputPlaceholder,
          "FORGOT_CREDENTIALS_UPDATE_CREDENTIAL_SUCCESSFULLY"
        );
        if (props.product === "iosnative") {
          mediator.base.dispatch({
            type: "LEAVE_CRF_SUCCESS",
            payload: {
              user:
                config.userIdField !== "email" ||
                (config.userIdField === "email" &&
                  get(user, "username") === get(user, "email"))
                  ? get(user, `${config.userIdField}`)
                  : null,
              recoveryType: isRecoveryTypePin ? "pin" : "password"
            }
          });
        } else {
          navigateToHome();
        }
        openLogin();
      })
      .catch((err) => {
        const code = get(err, "response.data.code");
        setErrorCode(code);
        setHasError(true);
      })
      .finally(() => {
        setNewCredential("");
      });
  };

  const resendRecoveryEmail = () => {
    mediator.base.dispatch({
      type: "FORGOT_CREDENTIALS_LINK_EXPIRED"
    });

    postResendCredentialsRecovery(props.token)
      .then(() => {
        setHasResentEmail(true);
      })
      .catch(() => {
        setHasError(true);
        setHasResentEmail(false);
        setErrorCode(null);
      });
  };

  const tryAgain = () => {
    setHasError(false);
    setLoading(false);
  };

  const onCloseModal = (module: string) => {
    mediator.base.dispatch({
      type: "FORGOT_CREDENTIALS_RETURN_TO_TVG_COM",
      payload: {
        module
      }
    });
    setHasError(false);
    navigateToHome();
  };

  const handleOnPrimaryCTAClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    switch (errorCode) {
      case ExpiredToken:
        resendRecoveryEmail();
        break;
      case InvalidPassword:
        tryAgain();
        break;
      default:
        openLiveChat();
    }
  };

  return (
    <CredentialsReset
      device={props.device}
      newCredential={newCredential}
      setNewCredential={setNewCredential}
      submitHandler={submitHandler}
      resetCredentials={{
        ...config,
        requirements: rulesValidated
      }}
      username={get(user, `${config.userIdField}`)}
      isLoading={loading}
      initialLoading={initialLoading}
      hasError={hasError}
      hasExpiredToken={errorCode === ExpiredToken}
      isInvalidPassword={errorCode === InvalidPassword}
      isPinReset={rulesValidated.length === 1}
      onCloseModalCallback={onCloseModal}
      modalData={getModalData(props.resetCredentialsMsgs, config)}
      errorData={errorDataMapper[errorCode] || defaultMsg}
      isModalOpen={isModalOpen}
      navigateHome={navigateToHome}
      onCloseModalCTA={(label) => {
        const gtmLabel = label.toLowerCase().includes("continue updating")
          ? "Continue Updating Credentials"
          : label;
        gtmHandler(gtmLabel, "FORGOT_CREDENTIALS_LEAVE_PAGE_CTA_CLICK");
        setIsModalOpen(false);
      }}
      onClose={() => {
        setIsModalOpen(false);
      }}
      onOpen={() => {
        mediator.base.dispatch({
          type: "FORGOT_CREDENTIALS_CANCEL_RESET",
          payload: {
            field: config.inputPlaceholder,
            module: "Forgot Credentials"
          }
        });
        setIsModalOpen(true);
      }}
      resendRecoveryEmail={resendRecoveryEmail}
      handleOnPrimaryCTAClick={handleOnPrimaryCTAClick}
      hasResentEmail={hasResentEmail}
      resentEmailMessage={props.resentEmailMessage}
      resentEmailPageMessage={props.resentEmailPageMessage}
      returnText={props.returnText}
      openLiveChat={openLiveChat}
      onBlurHandler={() =>
        gtmHandler(config.inputLabel, "FORGOT_CREDENTIALS_NEW_PWPIN")
      }
      isRn={props.product === "iosnative"}
    />
  );
};

ResetCredentials.defaultProps = {
  isLogged: false,
  resetType: "password",
  resetCredentialsConfig: {},
  resetCredentialsMsgs: {},
  resentEmailMessage: {
    title: "",
    message: ""
  },
  resentEmailPageMessage: {
    title: "",
    message: ""
  },
  returnText: "",
  device: "mobile"
};

export default connect((store) => ({
  resetCredentialsConfig: parseCapiMessage(
    store,
    "capi.messages.resetCredentials"
  ),
  resetCredentialsMsgs: parseCapiMessage(
    store,
    "capi.messages.resetCredentialsMsgs"
  ),
  resentEmailMessage: get(
    parseCapiMessage(store, "capi.messages.credentialsRecoveryModal"),
    "success.resent"
  ),
  resentEmailPageMessage: get(
    parseCapiMessage(store, "capi.messages.credentialsRecoveryModal"),
    "success.resentPage"
  ),
  returnText: get(
    parseCapiMessage(store, "capi.messages.credentialsRecoveryModal"),
    "return"
  )
}))(ResetCredentials);
