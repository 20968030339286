export type UserData = {
  error: boolean | null;
  hasRequested: boolean;
  logged: boolean;
  logging: boolean;
  user: {
    accountNumber: string;
    emailAddress: string;
    firstName: string;
    homeAddress: {
      streetNumber: string;
      address1: string;
      address2: string;
      city: string;
      stateAbbr: string;
    };
    lastName: string;
    mailingAddress: {
      streetNumber: string;
      address1: string;
      address2: string;
      city: string;
      stateAbbr: string;
    };
    primaryPhone: string;
    profile: string;
    signalProviderId: string;
    status: string;
    transportId: string;
    userName: string;
    wagerStatus: number;
    accountRoles: ["USER" | "TALENT" | "WAGEROPS" | "ADMIN"];
  };
  wasLogin: boolean;
  userLogin: boolean;
  userLogout: boolean;
  loginPin: number;
  tvg3token: string;
};

type UserSessionSuccessAction = {
  type: "USER_DATA_UPDATE";
  payload: UserData;
};

export type SetUserLoggedState = {
  type: "USER_LOGGED_STATE";
  payload: {
    isLogged: boolean;
  };
};

export type SetUserAccountNumber = {
  type: "USER_ACCOUNT_NUMBER";
  payload: {
    accountNumber: string;
  };
};

export type UserActions =
  | UserSessionSuccessAction
  | SetUserAccountNumber
  | SetUserLoggedState;

export const getUserData = (userData: UserData): UserSessionSuccessAction => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const setUserLoggedState = (isLogged: boolean): SetUserLoggedState => ({
  type: "USER_LOGGED_STATE",
  payload: {
    isLogged
  }
});

export const setUserAccountNumber = (
  accountNumber: string
): SetUserAccountNumber => ({
  type: "USER_ACCOUNT_NUMBER",
  payload: {
    accountNumber
  }
});
