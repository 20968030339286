"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("../geoComply/index"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const geoComply = new _index.default();
var _default = (payload, fn, useGeocomply) => {
  const newfn = geo => fn(payload, geo);
  if (useGeocomply) {
    return geoComply.getGeoPacket(payload.account).then(newfn).catch(newfn);
  }
  return fn(payload, {});
};
exports.default = _default;
module.exports = exports.default;