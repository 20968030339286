declare global {
  interface Window {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    perf: Record<string, any>;
  }
}

export default {
  register: (mark: string, timing: number | null = null) => {
    if (typeof window === "object" && window.perf) {
      window.perf.register(mark, timing);
    }
  },
  measure: (mark: string, tags: Record<string, string> | null = null) => {
    if (typeof window === "object" && window.perf) {
      window.perf.measure(mark, tags);
    }
  }
};
