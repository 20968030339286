// @flow

import type { styleMapType, styleMapSubtype } from "./types";
import buildColor from "../../_static/ColorPalette";
import {
  info,
  infoOutline,
  warningOutline,
  successOutline,
  glassyError,
  promo
} from "../../_static/Icons/icons";

export const styleMapByType: styleMapType = {
  default: {
    icon: info,
    fill: buildColor("blue_accent", "500"),
    background: buildColor("white", "0")
  },
  info: {
    icon: infoOutline,
    fill: buildColor("blue_accent", "600"),
    color: buildColor("blue_accent", "600"),
    iconBackground: buildColor("blue_accent", "200"),
    borderColor: buildColor("blue_accent", "200"),
    background: buildColor("blue_accent", "000")
  },
  warning: {
    icon: warningOutline,
    fill: buildColor("orange", "700"),
    color: buildColor("orange", "700"),
    borderColor: buildColor("orange", "100"),
    background: buildColor("orange", "000")
  },
  success: {
    icon: successOutline,
    fill: buildColor("green", "600"),
    color: buildColor("green", "600"),
    iconBackground: buildColor("green", "100"),
    borderColor: buildColor("green", "200"),
    background: buildColor("green", "000")
  },
  error: {
    icon: glassyError,
    fill: buildColor("red", "600"),
    color: buildColor("red", "600"),
    iconBackground: buildColor("red", "100"),
    borderColor: buildColor("red", "200"),
    background: buildColor("red", "000")
  },
  promos: {
    icon: promo,
    fill: buildColor("yellow", "800"),
    color: buildColor("yellow", "900"),
    borderColor: buildColor("yellow", "200"),
    background: buildColor("yellow", "000")
  }
};

export const styleMapBySubtype: styleMapSubtype = {
  contextual: {
    inlineText: true
  },
  fullWith: {
    hasBorderTop: true,
    hasBorderBottom: true
  },
  floating: {
    hasBorderLeft: true,
    hasBorderRight: true,
    hasBorderTop: true,
    hasBorderBottom: true,
    hasRoundCorners: true
  },
  scratchcard: {
    hasBorderLeft: true,
    hasBorderRight: true,
    hasBorderTop: true,
    hasBorderBottom: true,
    hasRoundCorners: true,
    hasDropShadow: true,
    hasCloseButton: true,
    hasCallToAction: true
  }
};

export default styleMapByType;
