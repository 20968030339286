import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

const Wrapper = styled.div<{ device: string; isRn: boolean }>`
  background-color: ${buildColor("blue", "000")};
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ device }) =>
    device === "desktop"
      ? css`
          align-items: center;
          margin: auto;

          & > form,
          & > div {
            position: relative;
            top: 0;
            margin-top: 0;
            inset: 0;
            max-height: 532px;

            @media screen and (orientation: landscape) {
              margin: 0 auto 20px;
            }
          }
        `
      : css`
          align-items: flex-start;
        `}

  ${({ isRn, device }) =>
    isRn &&
    device === "tablet" &&
    css`
      & > form,
      & > div {
        @media only screen and (min-height: 768px) {
          margin: 0 auto;
          top: calc(120px + 80px + 31px);
          position: fixed;
        }
        @media only screen and (max-height: 768px) {
          margin: 0;
          position: initial;
        }
      }
      @media only screen and (max-height: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `}
`;

export default Wrapper;
