import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const HeaderContainer = styled.header<{
  isMobile: boolean;
  isRn: boolean;
}>`
  width: 100%;
  ${({ isMobile }) =>
    isMobile
      ? css`
          margin: 16px 0;
          background-color: ${buildColor("white", "100")};
          box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
        `
      : css`
          position: relative;
          margin: 20px 0 80px;
          background-color: transparent;
        `}
  display: flex;
  justify-content: center;
  ${({ isRn, isMobile }) =>
    isRn &&
    isMobile &&
    css`
      top: 0;
      position: absolute;
      background-color: red;
      margin: 0;
      background-color: ${buildColor("white", "100")};
      height: 56px !important;
      display: flex;
      align-items: center;
      z-index: 20;
    `};
  ${({ isRn, isMobile }) =>
    isRn &&
    !isMobile &&
    css`
      @media only screen and (min-height: 768px) {
        margin: 0;
        position: fixed;
        top: 120px;
      }
      @media only screen and (max-height: 768px) {
        margin: 0;
        position: initial;
        margin-bottom: 80px;
      }
    `};
`;

export default HeaderContainer;
