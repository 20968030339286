// @flow

import React, { PureComponent, Fragment } from "react";
import { noop } from "lodash/fp";
import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";
import Text from "../../_atom/Text";
import Icon from "../../_static/Icons";
import { successOutline, account, email } from "../../_static/Icons/icons";
import Spinner from "../../_static/Spinners";
import buildColor from "../../_static/ColorPalette";
import ProcessingOverlay from "../../_static/ProcessingOverlay";
import Info from "../../_molecule/Info";
import MessageBox from "../../_molecule/MessageBoxV2";
import ModalV2 from "../../_templates/ModalV2";

import {
  Title,
  Form,
  RuleText,
  RulesList,
  FormContainer,
  StyledLink,
  FormLabel,
  FormGroup,
  FormInput,
  FormButton,
  FormFooter,
  RulesDescription,
  Container,
  CallToActionWrapper,
  ModalContent
} from "./styled-components";

type MessageType = {
  title: string,
  message: string,
  buttonText?: string,
  linkText?: string,
  returnToHomepage?: string
};

export type ResetCredentialsType = {
  title: string,
  userIdLabel: string,
  useIdField: string,
  inputLabel: string,
  inputPlaceholder: string,
  inputPattern: string,
  requirementsDescription?: string,
  recoveryType: number,
  requirements: Array<{
    rule: string,
    label: string,
    verified?: boolean
  }>,
  buttonText: string,
  linkText: string
};

type Props = {
  /**
   * Reset credentials data
   */
  resetCredentials: ResetCredentialsType,
  /**
   * New credential value
   */
  newCredential: string,
  /**
   * Submit button handler
   */
  setNewCredential: UnaryFn<string, void>,
  /**
   * Submit button handler
   */
  submitHandler: Function,
  /**
   * Email or Account number for logged in user
   */
  username?: string | number,
  /**
   * Enables spinner animation on form button
   */
  isLoading: boolean,
  /**
   * Displays processing overlay
   */
  initialLoading: boolean,
  /**
   * Displays error screen
   */
  hasError: boolean,
  /**
   * Represents "expired token error" scenarios
   */
  hasExpiredToken: boolean,
  /**
   * Represents "invalid password error" scenarios
   */
  isInvalidPassword: boolean,
  /**
   *  Closes modal
   */
  onCloseModalCallback?: UnaryFn<string, void>,
  /**
   * Contains error data
   */
  errorData: MessageType,
  isPinReset: boolean,
  /**
   * Device type "tablet" | "mobile" | "desktop"
   */
  device: string,
  modalData: MessageType,
  isModalOpen: boolean,
  onClose: Function,
  onOpen: Function,
  navigateHome: Function,
  handleOnPrimaryCTAClick: UnaryFn<Event, void>,
  hasResentEmail: boolean,
  resentEmailMessage: {
    title: string,
    message: string
  },
  resentEmailPageMessage: {
    title: string,
    message: string
  },
  returnText: string,
  /**
   * Handles on input blurr
   */
  onBlurHandler: Function,
  isRn: boolean,
  /**
   * Handles closing modal after CTA is clicked
   */
  onCloseModalCTA: Function
};

type State = {
  windowInnerHeight: number
};
export default class CredentialsReset extends PureComponent<Props, State> {
  static defaultProps = {
    resetCredentials: {
      title: "",
      userIdLabel: "",
      useIdField: "email",
      inputLabel: "",
      inputPlaceholder: "",
      inputPattern: "\\S",
      requirementsDescription: "",
      requirements: [],
      buttonText: "",
      linkText: ""
    },
    newCredential: "",
    setNewCredential: noop,
    submitHandler: noop,
    username: "",
    isLoading: false,
    initialLoading: true,
    hasError: false,
    hasExpiredToken: false,
    isInvalidPassword: false,
    onCloseModalCallback: noop,
    errorData: {
      title: "",
      message: "",
      buttonText: "",
      linkText: "",
      returnToHomepage: ""
    },
    isPinReset: false,
    device: "mobile",
    modalData: {
      title: "",
      message: "",
      buttonText: "",
      linkText: "",
      returnToHomepage: "Return to Homepage"
    },
    isModalOpen: false,
    onClose: () => {},
    onOpen: () => {},
    navigateHome: () => {},
    hasResentEmail: false,
    resentEmailMessage: {
      title: "",
      message: ""
    },
    resentEmailPageMessage: {
      title: "",
      message: ""
    },
    returnText: "",
    onBlurHandler: noop,
    isRn: false,
    onCloseModalCTA: noop
  };

  constructor(props: Props) {
    super(props);
    this.state = { windowInnerHeight: 0 };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.setWindowInnerHeight();
      window.addEventListener("resize", this.setWindowInnerHeight);
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.setWindowInnerHeight);
    }
  }

  setWindowInnerHeight = () => {
    this.setState({
      windowInnerHeight:
        document.documentElement?.clientHeight || window.innerHeight
    });
  };

  renderEmailResentMessage = (message: string) => (
    <span dangerouslySetInnerHTML={{ __html: message }} />
  );

  renderRules = (
    rules: Array<{
      rule: string,
      label: string,
      verified?: boolean
    }>,
    isPinReset: boolean
  ): Array<mixed> =>
    rules.map((rule, index) => (
      <RuleText
        data-qa-label={`input-rule-${index}`}
        isValid={rule.verified}
        isPinReset={isPinReset}
        key={rule.label}
      >
        <Icon
          icon={successOutline}
          color={buildColor("green", "800")}
          stoke={buildColor("green", "800")}
        />
        {rule.label}
      </RuleText>
    ));

  renderLoading = () => (
    <Container
      data-qa-label="reset-password-loading-container"
      device={this.props.device}
      isMobile={this.props.device === "mobile"}
      windowInnerHeight={this.state.windowInnerHeight}
    >
      <ProcessingOverlay />
    </Container>
  );

  renderSuccess = () => (
    <Container
      data-qa-label="reset-password-container-success"
      device={this.props.device}
      isMobile={this.props.device === "mobile"}
      windowInnerHeight={this.state.windowInnerHeight}
    >
      <Info
        qaLabel="reset-password-info-success"
        mainIcon={email}
        hasOverlayIcon
        messageType="success"
        title={this.props.resentEmailPageMessage.title}
        message={this.props.resentEmailPageMessage.message}
        full
      />
      <CallToActionWrapper>
        <StyledLink
          qaLabel="reset-password-cta-success"
          isStretched
          tag="link"
          size="huge"
          type="tertiary"
          onClick={(e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            const { onCloseModalCallback } = this.props;
            if (onCloseModalCallback) {
              onCloseModalCallback("New email was sent");
            }
          }}
        >
          {!this.props.isRn
            ? this.props.returnText
            : this.props.modalData.returnToHomepage}
        </StyledLink>
      </CallToActionWrapper>
    </Container>
  );

  getPrimaryCTAQALabel = () => {
    if (this.props.hasExpiredToken) {
      return "resend-recovery-email";
    }

    if (this.props.isInvalidPassword) {
      return "try-again";
    }

    return "open-live-person";
  };

  renderError = () => {
    const {
      errorData,
      device,
      hasExpiredToken,
      hasResentEmail,
      resentEmailMessage,
      isRn
    } = this.props;

    const infosData = hasExpiredToken
      ? {
          mainIcon: email,
          messageType: "warning"
        }
      : {
          mainIcon: account,
          messageType: "error"
        };

    return (
      <Container
        device={device}
        isMobile={device === "mobile"}
        windowInnerHeight={this.state.windowInnerHeight}
        data-qa-label="loginSignUpBlockFailure"
      >
        <Info
          qaLabel="reset-password-info-failure"
          {...infosData}
          title={errorData.title}
          message={errorData.message}
          hasOverlayIcon
          full
        />
        <CallToActionWrapper>
          {hasResentEmail ? (
            <MessageBox
              qaLabel="reset-password-reset-email"
              type="success"
              subtype="floating"
              title={resentEmailMessage.title}
              message={this.renderEmailResentMessage(
                resentEmailMessage.message
              )}
            />
          ) : (
            <StyledLink
              isStretched
              size="huge"
              url="#"
              isUppercase={false}
              onClick={this.props.handleOnPrimaryCTAClick}
              qaLabel={this.getPrimaryCTAQALabel()}
            >
              {errorData.buttonText}
            </StyledLink>
          )}
          <StyledLink
            isStretched
            tag="link"
            size="huge"
            type="tertiary"
            withMarginTop
            onClick={(e: Event) => {
              e.preventDefault();
              e.stopPropagation();
              const { onCloseModalCallback } = this.props;
              if (onCloseModalCallback) {
                onCloseModalCallback(
                  hasExpiredToken
                    ? "This link has expired"
                    : "Something went wrong!"
                );
              }
            }}
            qaLabel="close-modal-link"
          >
            {isRn ? errorData.returnToHomepage : errorData.linkText}
          </StyledLink>
        </CallToActionWrapper>
      </Container>
    );
  };

  renderModal = () => {
    const { modalData, onCloseModalCTA, navigateHome, isRn } = this.props;
    return (
      <ModalContent data-qa-label="reset-password-modal-content">
        <Text
          qaLabel="reset-password-modal-message"
          tag="p"
          lineHeight="18px"
          color={buildColor("grey", "800")}
        >
          {modalData.message}
        </Text>
        <FormButton
          qaLabel="reset-password-form-cta"
          insideModal
          size="big"
          isStretched
          isUppercase={false}
          onClick={() => onCloseModalCTA(modalData.buttonText)}
        >
          <Text tag="span" fontSize={14} lineHeight="16px" font>
            {modalData.buttonText}
          </Text>
        </FormButton>
        <StyledLink
          qaLabel="reset-password-redirect"
          isStretched
          tag="link"
          size="big"
          type="tertiary"
          onClick={(e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            navigateHome(modalData.linkText);
          }}
        >
          <Text tag="span" fontSize={14} lineHeight="16px">
            {isRn ? modalData.returnToHomepage : modalData.linkText}
          </Text>
        </StyledLink>
      </ModalContent>
    );
  };

  render() {
    const {
      resetCredentials,
      submitHandler,
      setNewCredential,
      newCredential,
      username,
      isLoading,
      initialLoading,
      hasError,
      hasResentEmail,
      isPinReset,
      device,
      modalData,
      isModalOpen,
      onOpen,
      onClose
    } = this.props;

    if (hasError) {
      return this.renderError();
    }

    if (hasResentEmail) {
      return this.renderSuccess();
    }

    if (initialLoading) {
      return this.renderLoading();
    }

    return (
      <Fragment>
        <ModalV2
          title={modalData.title}
          isOpen={isModalOpen}
          onClose={onClose}
          animation="fade"
          shouldBeClosable={false}
          isFullWidth={false}
          isFluidWidth={device !== "mobile"}
          isFullHeight={false}
          qaLabel="bridgeLogin-redirectModal"
        >
          {() => this.renderModal()}
        </ModalV2>
        <Form
          data-qa-label="reset-password-form"
          device={device}
          isMobile={device === "mobile"}
          windowInnerHeight={this.state.windowInnerHeight}
        >
          <FormContainer isMobile={device === "mobile"}>
            <Title
              data-qa-label="reset-password-form-title"
              fontSize={18}
              tag="p"
            >
              {resetCredentials.title}
            </Title>
            <FormGroup>
              <FormLabel
                data-qa-label="reset-password-form-label"
                tag="p"
                fontSize="14px"
              >
                {resetCredentials.userIdLabel}
              </FormLabel>
              <Text tag="p">{username}</Text>
            </FormGroup>
            <FormInput
              qaLabel="reset-password-password-input"
              loginV2
              status={null}
              type="password"
              placeholder={resetCredentials.inputPlaceholder}
              isButtonShown
              label={resetCredentials.inputLabel}
              value={newCredential}
              onChangeHandler={(_, value) => {
                const re = new RegExp(resetCredentials.inputPattern);

                if (value === "" || re.test(value)) {
                  return setNewCredential(value);
                }
                return re;
              }}
              onBlurHandler={() =>
                resetCredentials.requirements.every((rule) => rule.verified) &&
                this.props.onBlurHandler()
              }
            />
            <Fragment>
              {resetCredentials.requirementsDescription && (
                <RulesDescription data-qa-label="reset-password-rules-description">
                  {resetCredentials.requirementsDescription}
                </RulesDescription>
              )}
              <RulesList
                data-qa-label="reset-password-rules-list"
                hasBullets={resetCredentials.requirements.length > 1}
              >
                {this.renderRules(resetCredentials.requirements, isPinReset)}
              </RulesList>
            </Fragment>
            <FormFooter isMobile={device === "mobile"}>
              <FormButton
                qaLabel="reset-password-form-button"
                isDisabled={
                  !resetCredentials.requirements.every(
                    (rule) => rule.verified
                  ) && !isLoading
                }
                size="big"
                isStretched
                isUppercase={false}
                onClick={submitHandler}
              >
                {isLoading ? (
                  <Spinner color="white" height={14} width={14} />
                ) : (
                  resetCredentials.buttonText
                )}
              </FormButton>
              <StyledLink
                qaLabel="reset-password-form-link"
                isStretched
                tag="a"
                size="big"
                type="tertiary"
                onClick={(e: Event) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onOpen();
                }}
              >
                {resetCredentials.linkText}
              </StyledLink>
            </FormFooter>
          </FormContainer>
        </Form>
      </Fragment>
    );
  }
}
