import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers, { initialState } from "./reducers";

let composeEnhancers = compose;

/* eslint-disable */
// @ts-ignore
composeEnhancers =
  // @ts-ignore
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
/* eslint-enable */

export default (state = initialState) => {
  const middlewares = [thunk];
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  // @ts-ignore
  const store = createStore(reducers, state, enhancer);

  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept("./reducers", () =>
      import("./reducers").then((reducerModule) =>
        store.replaceReducer(reducerModule.default)
      )
    );
  }

  return store;
};
