// @flow
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import Icon from "../../_static/Icons";
import { fontNormal, fontMedium, defaultSize } from "../../_static/Typography";

export const HorizontalMessageBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  text-decoration: none;
  font-family: ${fontNormal};
  ${(props) =>
    css`
      background-color: ${props.customStyle.background};
      color: ${props.customStyle.color || props.customStyle.fill || "none"};
      border: ${props.customStyle.borderColor
        ? `1px solid ${props.customStyle.borderColor}`
        : "none"};
      ${!props.hasBorderLeft && "border-left: none;"};
      ${!props.hasBorderRight && "border-right: none;"};
      ${!props.hasBorderTop && "border-top: none;"};
      ${!props.hasBorderBottom && "border-bottom: none;"};
      border-radius: ${props.hasRoundCorners ? "4px" : "0"};
      ${props.hasDropShadow &&
      css`
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05);
      `};
      ${props.customStyle.iconBackground &&
      css`
        svg {
          border-radius: 50%;
          background-color: ${props.customStyle.iconBackground ||
          "transparent"};
        }
      `};
    `};
`;

export const IconTextWrapper = styled.div`
  display: flex;
`;

export const MessageIcon = styled(Icon).attrs({
  "data-qa-label": "messageBox-icon"
})`
  flex-shrink: 0;
`;

export const TextWrapper = styled.div`
  ${(props) =>
    props.inlineText
      ? css`
          display: inline;
        `
      : css`
          display: flex;
          flex-direction: column;
        `};
  justify-content: center;
  flex-grow: 1;
  margin-left: ${(props) => props.hasIcon && "12px"};
  min-height: ${(props) => props.hasIcon && "16px"};
`;

export const MessageTitle = styled.div`
  ${(props) => props.inlineText && "display: inline;"};
  font-family: ${fontMedium};
  font-size: ${defaultSize};
  font-weight: 500;
  line-height: 19px;
  vertical-align: top;
  margin-bottom: ${(props) => (props.inlineText ? "0" : "4px")};
  ${({ color }) => color && `color: ${color};`};
`;

export const MessageContent = styled.p`
  ${(props) => props.inlineText && "display: inline;"};
  font-family: ${fontNormal};
  font-size: ${defaultSize};
  line-height: 18px;
  vertical-align: top;
  ${({ color }) => color && `color: ${color};`};
`;

export const CloseBtn = styled.button`
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: 12px;
  background: none;
`;

export const ActionLink = styled(Link)`
  margin-top: 8px;
  font-family: ${fontMedium};
  font-size: ${defaultSize};
  font-weight: 500;
  line-height: 18px;
  text-decoration-line: underline;
  ${({ color }) => color && `color: ${color};`};
`;
