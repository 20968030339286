import mediator from "@tvg/mediator";

import { CapiActions } from "../../actions/capi";

export type State = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  featureToggles: any;
  messages: [];
  features: [];
};

export const initialState = {
  featureToggles: {},
  features: [],
  messages: []
};

export default function capiReducer(
  // @ts-ignore
  state: State = initialState,
  action: CapiActions
) {
  switch (action.type) {
    case "FEATURES_GET_SUCCESS": {
      mediator.base.dispatch({ type: "FEATURE_TOGGLES_LOADED", payload: {} });
      return { ...state, featureToggles: action.payload.featureToggles };
    }
    case "FEATURES_GET_FAIL": {
      return { ...state };
    }
    case "FEATURES_CHANGE": {
      const featureToggles = {
        ...state.featureToggles,
        [action.payload.toggleName]: action.payload.newValue
      };

      return { ...state, featureToggles };
    }
    case "FEATURES_RESPONSE": {
      return { ...state, features: action.payload.features };
    }
    case "MESSAGES_GET_SUCCESS":
      return { ...state, messages: action.payload.messages };
    case "MESSAGES_GET_FAIL": {
      return { ...state };
    }
    default: {
      return state;
    }
  }
}
