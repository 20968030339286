// @flow

import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontBold, fontMedium } from "../../_static/Typography";
import Input from "../../_molecule/LoginInput";
import Button from "../../_atom/Buttons/button";
import ButtonLink from "../../_atom/Buttons/buttonLink";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${buildColor("white", "100")};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${({ isMobile, windowInnerHeight }) =>
    isMobile
      ? css`
          top: initial;
          bottom: initial;
          height: ${windowInnerHeight ? `${windowInnerHeight}px` : "100vh"};
          width: 100vw;
          padding: 12px;
          padding-bottom: constant(safe-area-inset-bottom);
          padding-bottom: env(safe-area-inset-bottom);
        `
      : css`
          position: fixed;
          top: 80px;
          width: 391px;
          height: 450px;
          padding: 20px;
          margin: 192px auto 20px;

          @media screen and (orientation: landscape) {
            margin: auto;
          }
        `};
`;

export const Form = styled.form`
  width: 100%;
  flex: 1;
  font-size: 14px;
  background-color: ${buildColor("white", "100")};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  ${({ isMobile, windowInnerHeight }) =>
    isMobile
      ? css`
          height: ${windowInnerHeight
            ? css`calc(${windowInnerHeight}px - 56px)`
            : css`calc(100vh - 56px)`};
          padding-bottom: constant(safe-area-inset-bottom);
          padding-bottom: env(safe-area-inset-bottom);
        `
      : css`
          position: fixed;
          top: 80px;
          width: 391px;
          height: 450px;
          margin: 136px auto 20px;

          @media screen and (orientation: landscape) {
            margin: auto;
          }
        `};
`;

export const FormContainer = styled.div`
  padding: 12px 12px 20px 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${({ isMobile }) =>
    !isMobile &&
    `
    padding: 20px;
  `};
`;

export const Title = styled.p`
  color: ${buildColor("grey", "900")};
  font-size: 18px;
  font-family: ${fontBold};
  font-weight: 700;
  margin-bottom: 20px;
`;

export const RulesList = styled.ul`
  list-style: ${({ hasBullets }) => (hasBullets ? "disc" : "none")};
  padding-left: ${({ hasBullets }) => (hasBullets ? "25px" : 0)};
`;

export const RuleText = styled.li`
  color: ${({ isValid }) =>
    isValid ? buildColor("green", "800") : buildColor("grey", "700")};
  line-height: 18px;
  position: relative;
  padding-left: 6.5px;
  margin-left: ${({ isValid, isPinReset }) =>
    isValid && isPinReset ? "18px" : ""};

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  svg {
    display: ${({ isValid }) => (isValid ? "inline-block" : "none")};
    position: absolute;
    top: 50%;
    z-index: 1;
    background: ${buildColor("green", "100")};
    border-radius: 50%;
    transform: translateY(-50%);
    left: ${({ isPinReset }) => (isPinReset ? "-18px" : "-21px")};
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 20px;
`;

export const FormFooter = styled.div`
  ${({ isMobile }) => (isMobile ? "margin-top: 20px;" : "margin-top: auto;")};
`;

export const FormLabel = styled.label`
  color: ${buildColor("grey", "900")};
  display: inline-block;
  line-height: 19px;
  font-family: ${fontMedium};
  margin-bottom: 8px;
`;

export const FormInput = styled(Input)`
  label {
    line-height: 19px;
    margin-bottom: 5px;
    color: ${buildColor("grey", "900")};
  }
`;

export const RulesDescription = styled.p`
  color: ${buildColor("grey", "900")};
  font-family: ${fontNormal};
  line-height: 18px;
  margin-bottom: 12px;
`;

export const FormButton = styled(Button)`
  text-transform: capitalize;
  box-shadow: 0 2px 4px rgba(17, 43, 68, 0.06), 0 1px 2px rgba(17, 43, 68, 0.08);
  margin-bottom: ${({ insideModal }) => (insideModal ? "10px" : "12px")};
  margin-top: ${({ insideModal }) => insideModal && "14.5px"};
  font-family: ${fontMedium};
  opacity: ${({ isDisabled }) => isDisabled && "0.6"};
  span {
    font-family: ${fontNormal};
  }
`;

export const CallToActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledLink = styled(ButtonLink)`
  font-family: ${fontMedium};
  span {
    font-family: ${fontNormal};
  }

  ${({ withMarginTop }) => withMarginTop && "margin-top: 10px;"}
`;

export const ModalContent = styled.div`
  padding: 16px 12px 10px 12px;
  background-color: ${buildColor("white", "100")};
`;
