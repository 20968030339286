import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import fetch from "node-fetch";

import TVGConf from "@tvg/conf";

export type GraphClient = "graph" | "fcp" | "rda" | "gas";

const getClientURI = (graphClient: GraphClient): string => {
  let uri = "";

  switch (graphClient) {
    case "fcp":
      uri = TVGConf().config().service.fcp;
      break;
    case "rda":
      uri = TVGConf().config().service.rda;
      break;
    case "gas":
      uri = TVGConf().config().service.gas;
      break;
    default:
      uri = TVGConf().config().service.graph;
      break;
  }

  return uri;
};

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getClient(graphClient: GraphClient = "graph"): any {
    switch (graphClient) {
      case "rda":
        // @ts-ignore
        return this.rdaClient;
      case "fcp":
        // @ts-ignore
        return this.fcpClient;
      case "gas":
        // @ts-ignore
        return this.gasClient;
      default:
        // @ts-ignore
        return this.graphClient;
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setClient(client: any, type: GraphClient): void {
    switch (type) {
      case "rda":
        // @ts-ignore
        this.rdaClient = client;
        break;
      case "fcp":
        // @ts-ignore
        this.fcpClient = client;
        break;
      case "gas":
        // @ts-ignore
        this.gasClient = client;
        break;
      default:
        // @ts-ignore
        this.graphClient = client;
        break;
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createClient(ssrMode = false, graphClient: GraphClient = "graph"): any {
    const httpLink = createHttpLink({
      // @ts-ignore
      fetch,
      uri: getClientURI(graphClient),
      credentials: "include"
    });

    const retryLink = new RetryLink({
      // @ts-ignore
      delay: 15000,
      // @ts-ignore
      interval: (delay, count) => {
        if (count > 5) return 15000;
        return delay;
      }
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          // eslint-disable-next-line no-console
          console.error(
            `[GraphQL error]: Operation: ${operation.operationName}, Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );

      if (networkError) {
        console.error(`[Network error]: ${networkError}`); // eslint-disable-line no-console
      }
    });

    let link;
    if (typeof window === "undefined") {
      link = errorLink.concat(httpLink);
    } else {
      link = retryLink.concat(errorLink).concat(httpLink);
    }

    const cache = new InMemoryCache();

    if (typeof window !== "undefined") {
      // @ts-ignore
      cache.restore(window.__APOLLO_STATE__); // eslint-disable-line no-underscore-dangle
    }

    const client = new ApolloClient({
      link,
      cache,
      // @ts-ignore
      addTypename: true,
      ssrMode
    });

    this.setClient(client, graphClient);

    return client;
  }
};
