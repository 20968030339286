import React from "react";
import { ApolloProvider } from "@apollo/client";
// @ts-ignore
import { AppRegistry } from "react-native-web";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ApolloContext from "@tvg/utils/apolloContext";
import tvgConf from "@tvg/conf/cjs/src/index";
import ApolloClient from "./apolloClient/ApolloClient";
import configureStore from "./configureStore";
import Main from "./pages";

// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
const preloadedState = window.__RCF_PRELOADED_STATE__;

const store = configureStore(preloadedState);
// gets conf for specific host / device
tvgConf(window.location.hostname, window.navigator.userAgent);

const App = () => (
  <ApolloProvider client={ApolloClient.createClient(false)}>
    <ApolloContext.Provider
      value={{
        gasClient: ApolloClient.createClient(false, "gas")
      }}
    >
      {/* @ts-ignore */}
      <Provider store={store}>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </Provider>
    </ApolloContext.Provider>
  </ApolloProvider>
);

const Render = () => {
  if (document.getElementById("credentialsReset")) {
    AppRegistry.registerComponent("App", () => App);

    AppRegistry.runApplication("App", {
      initialProps: {},
      rootTag: document.getElementById("credentialsReset")
    });
  }
};

export default Render();
