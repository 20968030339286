"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.onError = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _conf = _interopRequireDefault(require("@tvg/conf"));
var _helper = require("../services/helper");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const onError = () => ({
  error: {
    status: "error",
    message: "Profile request fail"
  },
  success: null
});
exports.onError = onError;
const onSuccess = response => {
  const user = {
    accountNumber: response.data.accountNumber,
    accountRoles: response.data.accountRoles,
    firstName: response.data.firstName,
    lastName: response.data.lastName,
    primaryPhone: response.data.primaryPhone,
    wagerStatus: response.data.wagerStatus,
    emailAddress: response.data.emailAddress,
    homeAddress: response.data.homeAddress,
    mailingAddress: response.data.mailingAddress,
    signalProviderId: response.data.signalProviderId,
    userName: response.data.userName,
    profile: response.data.profile,
    pin: ""
  };
  return {
    error: null,
    success: {
      status: "success",
      data: {
        user
      }
    }
  };
};
var _default = userId => (0, _axios.default)({
  method: "get",
  url: `${(0, _conf.default)().config().service.uam}/users/${userId}/profile`,
  headers: (0, _helper.tvgHeaders)(),
  withCredentials: true
}).then(onSuccess).catch(onError);
exports.default = _default;