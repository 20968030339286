import { combineReducers } from "redux";

import capiReducer, {
  State as CapiState,
  initialState as initialCapiState
} from "./App/capiReducer";

import userDataReducer, {
  State as UserState,
  initialState as initialUserState
} from "./App/userDataReducer";

export type State = {
  capi: CapiState;
  userData: UserState;
};

export const initialState = {
  capi: initialCapiState,
  userData: initialUserState
};

// @ts-ignore
export default combineReducers({
  capi: capiReducer,
  userData: userDataReducer
});
