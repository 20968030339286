import styled, { css } from "styled-components";
import { transparentize } from "polished";
import {
  fontNormal,
  fontMedium,
  fontBold,
  defaultSize
} from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const InfoWrapper = styled.div`
  align-items: center;
  background-color: ${buildColor("white", "100")};
  max-width: 100%;
  padding-bottom: 20px;
  height: auto;
  flex-wrap: wrap;
  ${({ full }) =>
    full
      ? css`
          display: column;
          margin: auto;
          padding-top: 22px;
        `
      : css`
          display: flex;
        `}
`;

export const InfoLogoWrapper = styled.span`
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${buildColor("grey", "100")};
  border-radius: 40px;
  ${({ full }) =>
    full &&
    css`
      margin: auto;
    `}
`;

export const OverlayIconContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${buildColor("white", "100")};
  border-radius: 50%;
  ${({ full }) =>
    full
      ? css`
          width: 20px;
          height: 20px;
        `
      : css`
          width: 14px;
          height: 14px;
        `}
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  word-wrap: break-word;
  ${({ full }) =>
    full &&
    css`
      margin-top: 22px;
    `}
`;

export const InfoTitle = styled.p`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: ${defaultSize};
  font-weight: 700;
  letter-spacing: 0;
  ${({ full }) =>
    full &&
    css`
      margin: auto;
    `}
`;

export const InfoMessage = styled.div`
  color: ${buildColor("grey", "800")};
  font-size: ${defaultSize};
  font-family: ${fontNormal};
  font-weight: 400;
  letter-spacing: 0;
  padding-top: 4px;
  padding-right: 22px;
  ${({ full }) =>
    full &&
    css`
      margin: auto;
      text-align: center;
      padding: 4px 36px 0;
    `}
`;

export const ButtonContainer = styled.div`
  background: ${buildColor("white", "100")};
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 12px 12px 8px;

  & > button,
  > a {
    font-family: ${fontMedium};
    font-weight: 500;
    text-transform: none;
    box-shadow: 0 2px 2px ${transparentize(0.85, buildColor("blue", "900"))};
  }
`;
