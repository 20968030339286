// @flow
import React from "react";
import { withTheme } from "styled-components";
import { noop } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import {
  refresh,
  info,
  error as errorIcon,
  success,
  exclamation
} from "../../_static/Icons/icons";
import type { IconType } from "../../_static/Icons/Types.js.flow";
import Button from "../../_atom/Buttons/button";
import ButtonLink from "../../_atom/Buttons/buttonLink";

import {
  InfoWrapper,
  InfoLogoWrapper,
  OverlayIconContainer,
  InfoContainer,
  InfoTitle,
  InfoMessage,
  ButtonContainer
} from "./styled-components";

export type MessageType = "error" | "info" | "success" | "warning";

type Props = {
  messageType: MessageType,
  title?: string,
  message?: string | Node,
  mainIcon?: IconType,
  hasOverlayIcon?: boolean,
  overlayIcon?: IconType,
  overlayIconColor?: string,
  full?: boolean,
  hasButton?: boolean,
  buttonText?: string,
  buttonHandler?: NullaryFn<void>,
  buttonUrl?: string,
  qaLabel: string
};

const LOGO_SIZE_M = 40;
const LOGO_SIZE_L = 60;

const MAIN_ICON_SIZE_M = 18;
const MAIN_ICON_SIZE_L = 24;

const OVERLAY_ICON_SIZE_M = 12;
const OVERLAY_ICON_SIZE_L = 16;

const getIcon = (
  messageType: MessageType,
  overlayIcon?: IconType,
  overlayIconColor?: string
) => {
  switch (messageType) {
    case "info": {
      return {
        color: buildColor("blue_accent", "400"),
        icon: info
      };
    }
    case "success": {
      return {
        color: buildColor("green", "500"),
        icon: success
      };
    }
    case "warning": {
      return {
        color: buildColor("yellow", "500"),
        icon: exclamation
      };
    }
    case "error": {
      return {
        color: buildColor("red", "500"),
        icon: errorIcon
      };
    }
    default: {
      return {
        color: overlayIconColor || buildColor("red", "500"),
        icon: overlayIcon || errorIcon
      };
    }
  }
};

const Info = ({
  messageType,
  title,
  message,
  mainIcon,
  overlayIcon,
  overlayIconColor,
  hasOverlayIcon,
  full,
  hasButton,
  buttonText,
  buttonHandler,
  buttonUrl,
  qaLabel
}: Props) => {
  const overlayIconProps = getIcon(messageType, overlayIcon, overlayIconColor);
  return (
    <InfoWrapper full={full} data-qa-label={`${qaLabel}-wrapper`}>
      <InfoLogoWrapper
        full={full}
        size={full ? LOGO_SIZE_L : LOGO_SIZE_M}
        data-qa-label={`${qaLabel}-logo-wrapper`}
      >
        <Icon
          icon={mainIcon}
          size={full ? MAIN_ICON_SIZE_L : MAIN_ICON_SIZE_M}
          qaLabel={`${qaLabel}-main-icon`}
          color={buildColor("grey", "900")}
        />
        {hasOverlayIcon && (
          <OverlayIconContainer full={full}>
            <Icon
              icon={overlayIconProps.icon}
              size={full ? OVERLAY_ICON_SIZE_L : OVERLAY_ICON_SIZE_M}
              qaLabel={`${qaLabel}-overlay-icon`}
              color={overlayIconProps.color}
            />
          </OverlayIconContainer>
        )}
      </InfoLogoWrapper>
      <InfoContainer full={full}>
        <InfoTitle full={full} data-qa-label={`${qaLabel}-title`}>
          {title}
        </InfoTitle>
        <InfoMessage
          full={full}
          data-qa-label={`${qaLabel}-message`}
          as={typeof message === "string" ? "p" : "div"}
        >
          {message}
        </InfoMessage>
      </InfoContainer>
      {hasButton && (
        <ButtonContainer>
          {buttonUrl ? (
            <ButtonLink
              type="secondary"
              size="bigger"
              isStretched
              onClick={buttonHandler}
              url={buttonUrl}
              qaLabel={`${qaLabel}-button`}
            >
              {buttonText}
            </ButtonLink>
          ) : (
            <Button
              type="secondary"
              size="bigger"
              isStretched
              onClick={buttonHandler || window.location.reload}
              qaLabel={`${qaLabel}-button`}
            >
              {buttonText}
            </Button>
          )}
        </ButtonContainer>
      )}
    </InfoWrapper>
  );
};

Info.defaultProps = {
  messageType: "error",
  mainIcon: refresh,
  title: "Something went wrong",
  message:
    "Sorry we're having tech issues, we are working to resolve these quickly. Check back soon.",
  overlayIcon: errorIcon,
  overlayIconColor: "",
  hasOverlayIcon: true,
  full: false,
  hasButton: false,
  buttonText: "Refresh",
  buttonHandler: noop,
  buttonUrl: "",
  qaLabel: "info-message"
};

// $FlowFixMe - Cannot call React.memo because property memo is missing in object type
export default React.memo(withTheme(Info));
