// @ts-ignore
import cookie from "react-cookie";

const REGEX_PREFIX_TO_REMOVE = new RegExp("^(www|local)");

export const cookieService = (
  cookieName: string,
  actionType: boolean,
  cookieValue?: string,
  maxAge?: number
): void => {
  if (typeof window !== "undefined") {
    const domain = window.location.hostname;
    const domainForCookie = REGEX_PREFIX_TO_REMOVE.test(domain)
      ? domain.substring(domain.indexOf("."))
      : `.${domain}`;

    if (cookie.load(cookieName, { domain: domainForCookie })) {
      cookie.remove(cookieName, { domain: domainForCookie });
    }

    // add cookies with time
    if (actionType) {
      cookie.save(
        cookieName,
        cookieValue,
        maxAge
          ? { domain: domainForCookie, maxAge }
          : { domain: domainForCookie }
      );
    }
  }
};
