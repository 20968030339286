import tvgConf from "@tvg/conf";
import requester from "../requester";
import getProtocol from "../protocolSetter";
import { serviceResponse } from "../types";

const crfService: string = "service.crf";
const getCrfServiceUrl = tvgConf().config(crfService);
const context = tvgConf().context();

export const postUserCredentialsReset = (
  token: string
): Promise<serviceResponse> => {
  const url = `${getProtocol()}${getCrfServiceUrl}/user`;
  const requestOptions = {
    method: "POST",
    url,
    headers: {
      "content-type": "application/json",
      "x-tvg-context": context
    },
    data: {
      token
    },
    withCredentials: false
  };

  return requester()(requestOptions);
};

export const postCredentialsRecovery = (userId: string): Promise<{}> => {
  const url = `${getProtocol()}${getCrfServiceUrl}/token`;

  const requestOptions = {
    method: "POST",
    url,
    headers: {
      "content-type": "application/json",
      "x-tvg-context": context
    },
    data: {
      id: userId
    },
    withCredentials: false
  };

  return requester()(requestOptions);
};

export const postResendCredentialsRecovery = (token: string): Promise<{}> => {
  const url = `${getProtocol()}${getCrfServiceUrl}/reset-token`;

  const requestOptions = {
    method: "POST",
    url,
    headers: {
      "content-type": "application/json",
      "x-tvg-context": context
    },
    data: {
      token
    },
    withCredentials: false
  };

  return requester()(requestOptions);
};

export const postNewCredential = (
  token: string,
  newPassword: string
): Promise<{}> => {
  const url = `${getProtocol()}${getCrfServiceUrl}/reset-password`;

  const requestOptions = {
    method: "POST",
    url,
    headers: {
      "content-type": "application/json",
      "x-tvg-context": context
    },
    data: {
      token,
      credentials: newPassword
    },
    withCredentials: false
  };

  return requester()(requestOptions);
};

export const postNewPin = (token: string, newPassword: string): Promise<{}> => {
  const url = `${getProtocol()}${getCrfServiceUrl}/reset-pin`;

  const requestOptions = {
    method: "POST",
    url,
    headers: {
      "content-type": "application/json",
      "x-tvg-context": context
    },
    data: {
      token,
      credentials: newPassword
    },
    withCredentials: false
  };

  return requester()(requestOptions);
};

/**
 * Recover user's login with date of birth and last 4 ssn digits Parameters
 * @returns {Promise<{}>}
 */
export const postRecoveryUsername = (
  dateOfBirth: string,
  last4Ssn: string
): Promise<{
  data: Record<string, number | string>;
  isAxiosError: boolean;
}> => {
  const conf = tvgConf();
  const url = `${conf.config().service.crf}/recovery`;

  const requestOptions = {
    method: "POST",
    url,
    headers: {
      "content-type": "application/json",
      "x-tvg-context": conf.context()
    },
    data: {
      birthday: dateOfBirth,
      last4Ssn
    },
    withCredentials: false
  };

  return requester()(requestOptions) as unknown as Promise<{
    data: Record<string, number | string>;
    isAxiosError: boolean;
  }>;
};
