import { UserActions } from "../../actions/user";

export type State = {
  error: boolean;
  hasRequested: boolean;
  logged: boolean | typeof undefined;
  logging: boolean;
  user: {
    accountNumber: string;
    emailAddress: string;
    firstName: string;
    homeAddress: {
      streetNumber: string;
      address1: string;
      address2: string;
      city: string;
      stateAbbr: string;
    };
    lastName: string;
    mailingAddress: {
      streetNumber: string;
      address1: string;
      address2: string;
      city: string;
      stateAbbr: string;
    };
    primaryPhone: string;
    profile: string;
    signalProviderId: string;
    status: string;
    transportId: string;
    userName: string;
    wagerStatus: number;
    accountRoles: ["USER" | "TALENT" | "WAGEROPS" | "ADMIN"];
  };
  wasLogin: boolean;
};

export const initialState = {
  error: false,
  hasRequested: false,
  logged: undefined,
  logging: false,
  loginPin: 0,
  tvg3token: "",
  userLogin: false,
  userLogout: true,
  user: {
    accountNumber: "",
    emailAddress: "",
    firstName: "",
    homeAddress: {
      streetNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      stateAbbr: ""
    },
    lastName: "",
    mailingAddress: {
      streetNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      stateAbbr: ""
    },
    primaryPhone: "",
    profile: "PORT-Generic",
    signalProviderId: "",
    status: "",
    transportId: "",
    userName: "",
    wagerStatus: 0,
    accountRoles: ["USER"]
  },
  wasLogin: false
};

// @ts-ignore
const userDataReducer = (state: State = initialState, action: UserActions) => {
  switch (action.type) {
    case "USER_DATA_UPDATE": {
      return { ...state, ...action.payload };
    }
    case "USER_LOGGED_STATE": {
      return { ...state, logged: action.payload.isLogged, hasRequested: true };
    }
    case "USER_ACCOUNT_NUMBER": {
      return {
        ...state,
        user: { ...state.user, accountNumber: action.payload.accountNumber }
      };
    }
    default: {
      return state;
    }
  }
};

export default userDataReducer;
